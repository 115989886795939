import { Settings } from 'luxon';
import StoryChief from '@/storychief';

export default function initLuxon() {
  Settings.defaultLocale = 'en';

  if (StoryChief.timezone) {
    Settings.defaultZone = StoryChief.timezone;
  }

  Settings.defaultWeekSettings = {
    firstDay: StoryChief.firstDayWeek === 0 ? 7 : 1,
    minimalDays: 1,
    weekend: [6, 7],
  };
}
