// eslint-disable-next-line import/prefer-default-export
export const COLOR_NONE = -1;

// Actions
export const ACTION_PUBLISH = 1;
export const ACTION_UNPUBLISH = 2;
export const ACTION_UPDATE = 3;
export const ACTION_SCHEDULE = 4;
export const ACTION_UNSCHEDULE = 5;
export const ACTION_PUBLISH_AS_DRAFT = 6;
export const ACTION_NAMES = {
  [ACTION_PUBLISH]: 'Publish',
  [ACTION_UNPUBLISH]: 'Unpublish',
  [ACTION_UPDATE]: 'Update',
  [ACTION_SCHEDULE]: 'Schedule',
  [ACTION_UNSCHEDULE]: 'Unschedule',
  [ACTION_PUBLISH_AS_DRAFT]: 'Draft',
};

// Statuses
export const STATUS_DRAFT = 1;
export const STATUS_SCHEDULED = 3;
export const STATUS_PUBLISHED = 4;
export const STATUS_UNPUBLISHED = 5;
export const STATUS_REPUBLISHED = 6;
export const STATUS_FAILED = 7;
export const STATUS_DELETED = 8;
export const STATUS_IDLE = 9;
export const STATUS_NAMES = {
  [STATUS_DRAFT]: 'Draft',
  [STATUS_SCHEDULED]: 'Scheduled',
  [STATUS_PUBLISHED]: 'Published',
  [STATUS_UNPUBLISHED]: 'Unpublished',
  [STATUS_REPUBLISHED]: 'Republished',
  [STATUS_FAILED]: 'Failed',
  [STATUS_DELETED]: 'Deleted',
  [STATUS_IDLE]: 'Idle',
};

// Image widths
export const IMAGE_SIZE_THUMB_WIDTH = 60;
export const IMAGE_SIZE_MEDIUM_WIDTH = 400;
export const IMAGE_SIZE_REGULAR_WIDTH = 800;
export const IMAGE_SIZE_LARGE_WIDTH = 1000;
export const IMAGE_SIZE_FULL_WIDTH = 2000;

// Integrations
export const INTEGRATION_CAPTURE_TEAMLEADER = 1;
export const INTEGRATION_CAPTURE_EMAIL = 2;
export const INTEGRATION_CAPTURE_MAILCHIMP = 3;
export const INTEGRATION_CAPTURE_HUBSPOT = 4;
export const INTEGRATION_CAPTURE_SALESFORCE = 5;
export const INTEGRATION_CAPTURE_AMBASSADORS = 6;
export const INTEGRATION_CAPTURE_PRESS = 7;
export const INTEGRATION_CAPTURE_SALESFLARE = 8;
export const INTEGRATION_CAPTURE_WEBHOOKS = 9;
export const INTEGRATION_DAM_CANTO = 10;
export const INTEGRATION_DAM_GOOGLE_DRIVE = 11;
export const INTEGRATION_DAM_DROPBOX = 12;
export const INTEGRATION_DAM_CLOUDINARY = 13;
export const INTEGRATION_DAM_BYNDER = 14;
export const INTEGRATION_DAM_ONEDRIVE = 15;
export const INTEGRATION_DAM_UNSPLASH = 16;
export const CAPTURE_INTEGRATIONS = [
  INTEGRATION_CAPTURE_TEAMLEADER,
  INTEGRATION_CAPTURE_EMAIL,
  INTEGRATION_CAPTURE_MAILCHIMP,
  INTEGRATION_CAPTURE_HUBSPOT,
  INTEGRATION_CAPTURE_SALESFORCE,
  INTEGRATION_CAPTURE_AMBASSADORS,
  INTEGRATION_CAPTURE_PRESS,
  INTEGRATION_CAPTURE_SALESFLARE,
  INTEGRATION_CAPTURE_WEBHOOKS,
];
export const DAM_INTEGRATIONS_WITHOUT_SETUP = [
  INTEGRATION_DAM_UNSPLASH,
  INTEGRATION_DAM_GOOGLE_DRIVE,
  INTEGRATION_DAM_DROPBOX,
  INTEGRATION_DAM_ONEDRIVE,
];
export const DAM_INTEGRATIONS_WITH_SETUP = [
  INTEGRATION_DAM_CANTO,
  INTEGRATION_DAM_CLOUDINARY,
  INTEGRATION_DAM_BYNDER,
];
export const INTEGRATION_NAMES = {
  [INTEGRATION_CAPTURE_TEAMLEADER]: 'Teamleader',
  [INTEGRATION_CAPTURE_EMAIL]: 'Email',
  [INTEGRATION_CAPTURE_MAILCHIMP]: 'Mailchimp',
  [INTEGRATION_CAPTURE_HUBSPOT]: 'Hubspot',
  [INTEGRATION_CAPTURE_SALESFORCE]: 'Salesforce',
  [INTEGRATION_CAPTURE_AMBASSADORS]: 'Ambassadors',
  [INTEGRATION_CAPTURE_PRESS]: 'Press',
  [INTEGRATION_CAPTURE_SALESFLARE]: 'Salesflare',
  [INTEGRATION_CAPTURE_WEBHOOKS]: 'Webhook',
  [INTEGRATION_DAM_CANTO]: 'Canto',
  [INTEGRATION_DAM_GOOGLE_DRIVE]: 'Google Drive',
  [INTEGRATION_DAM_DROPBOX]: 'Dropbox',
  [INTEGRATION_DAM_CLOUDINARY]: 'Cloudinary',
  [INTEGRATION_DAM_BYNDER]: 'Bynder',
  [INTEGRATION_DAM_ONEDRIVE]: 'OneDrive',
  [INTEGRATION_DAM_UNSPLASH]: 'Unsplash',
};

// Color Coding
export const COLOR_CODING = {
  // This list is intentionally not sorted by values since the values are grouped by colors that are similar
  COLOR_15: 15,
  COLOR_1: 1,
  COLOR_13: 13,
  COLOR_2: 2,
  COLOR_16: 16,
  COLOR_3: 3,
  COLOR_17: 17,
  COLOR_4: 4,
  COLOR_18: 18,
  COLOR_5: 5,
  COLOR_19: 19,
  COLOR_6: 6,
  COLOR_20: 20,
  COLOR_14: 14,
  COLOR_7: 7,
  COLOR_21: 21,
  COLOR_8: 8,
  COLOR_22: 22,
  COLOR_9: 9,
  COLOR_23: 23,
  COLOR_10: 10,
  COLOR_24: 24,
  COLOR_11: 11,
  COLOR_12: 12,
};

// CFV with options
export const CFV_WITH_OPTIONS = JSON.stringify(['radio', 'checkbox', 'select']);

// Variants
export const SWITCH_TAB_VARIANTS = {
  primary: 'primary',
  accent: 'accent',
  secondary: 'secondary',
  light: 'light',
};

// Duplicate content
export const DUPLICATE_CONTENT_TYPES = {
  stories: 'Story',
  postsets: 'Postset',
};

export const TOOLTIP_DELAY = 500;

export const DEFAULT_JED_TRANSLATIONS = {
  domain: 'js-text-analysis',
  locale_data: {
    'js-text-analysis': {
      '': {},
    },
  },
};

export const KEYWORDS_SELECT_ID = 'keywords-select';
