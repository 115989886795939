import { DateTime } from 'luxon';
import type { DateRaw } from './types';
import initLuxon from '@/date/initLuxon';

initLuxon();

function getDateTimeObject(date?: DateRaw) {
  if (!date) {
    return DateTime.now();
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }

  return date;
}

export default function getDateObject(date?: DateRaw, timezone = 'system'): DateTime {
  return getDateTimeObject(date).setZone(timezone);
}
